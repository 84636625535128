import React from 'react';
import ReactPlayer from 'react-player';
import Video from './tf.mp4';
import Heart from './heart.svg';
import './App.scss';

function App() {
  return (
      <div className="border">
    <div className="wrapper">
        <div className="video-wrapper">
      <ReactPlayer width={"auto"} className="video" loop={true} controls={false} muted={true} playing={true} url={Video} />
        </div>
        <div className="content">
            <div className="content-left">
                <span className="content-text">Tanja Feirer ist eine Grafikdesignerin mit Sitz in Graz. Ganzheitlich durchdacht und mit viel Liebe zum Detail gestaltet sie individuelle </span>
                <span className="content-text">Logos & Corporate Identitys, Editorial Designs & Layouts, verschiedenste Printprodukte, Verpackungen, Websites, Illustrationen und mehr.</span>
            </div>
            <div className="content-right">
                <div className="content-right-wrapper">
                    <div className="contact-links">
                        <a className="content-text" href="mailto:hello@tanja-feirer.at">hello@tanja-feirer.at</a>
                        <a className="content-text" href="tel:+436802191822">+43 680 21 91 822</a>
                    </div>
                    <div className="heart">
                        <img src={Heart} />
                    </div>
                </div>
            </div>
        </div>
    </div>
      </div>
  );
}

export default App;
